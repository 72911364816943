import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Container, Grid} from "@material-ui/core";
import logo from "../../static/logo.svg"
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 445,
    },
    media: {
        height: 240,
    },
});

export default function StackSwitcher({isDynamic}) {
    const classes = useStyles();
    const navigate = useNavigate()

    const [stacks, setStacks] = useState([])

    useEffect(() => {
        let allStacks = JSON.parse(localStorage.getItem('stacks'))
        setStacks(allStacks.filter(stack => stack.type === 'core'))
    },[])

    function handleClick(url) {
        const domain = url.replace(/(^\w+:|^)\/\//, '')
        localStorage.setItem('selectedStackDomain', domain)
        navigate(isDynamic ? '/dynamic/institution-switcher' : '/institution-switcher')
    }

    function renderStacks() {
        if (stacks && Array.isArray(stacks) && stacks.length > 0) {
            return stacks.map(stack => {
                return (
                    <Grid key={stack.uuid} item lg={4}>
                        <Card className={classes.root} onClick={() => handleClick(stack.url)}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    style={{background:"url(" + stack.image + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: 'center'}}
                                    // image={stack.image}
                                    title={stack.name}
                                    component="div"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {stack.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {stack.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            })
        } else {
            return <Typography variant={'body1'} >Could not find any stacks to select.</Typography>

        }
    }

    return (
        <Container>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{marginTop: 48}}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img alt={"Stack banner"} src={logo} width={360}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'body1'} style={{color: 'grey', paddingBottom: 8, paddingTop: 8}}>Select the system you want to use.</Typography>
                    <Typography variant={'body1'}><a href={"https://help.practera.com/en/articles/5391260-login-access-to-multiple-systems"} target={"_blank"} rel="noreferrer">Not sure which to choose?</a> </Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                </Grid>
                {renderStacks()}
            </Grid>
        </Container>
    );
}
