import {Container, Grid} from "@material-ui/core";
import React from "react";
import {Alert} from "@material-ui/lab";

export default function Error({message}) {
    return (
        <Container>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{marginTop: 48}}>
                <Grid item xs={6} style={{textAlign: 'center'}}>
                    <Alert severity="error">{message ? message : 'Error!'}</Alert>
                </Grid>
            </Grid>
        </Container>
    )
}
