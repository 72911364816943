import React, {useEffect, useState} from 'react';
import Hidden from "@material-ui/core/Hidden";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, FormControl, FormHelperText, Grid, Input, InputLabel} from "@material-ui/core";
import {isValidGlobalLoginJwt, login} from "../../services/practeraApi";
import Loading from "../util/Loading";
import LoadingSmall from "../util/LoadingSmall";
import banner from '../../static/employ_banner.png';
import logo from '../../static/logo.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 50
    },
    banner: {
        position:'fixed',
        width: '100%',
        zIndex: 1201,
        textAlign:'center',
        backgroundColor: 'lavender',
        height: 40,
        fontWeight: 700,
        lineHeight: '40px'
    },
    heroImage: {
        width: '100%',
        height: '70vh',
        background: `url(${banner})`,
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center'
    },
    logo: {
        width: '70%',
        height: '20vh',
        background:`url(${logo})`,
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center'
    }
}));

export default function Login({onSuccessfulLogin}) {
    const classes = useStyles();

    const [isFetching, setIsFetching] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [isSaving, setIsSaving] = useState(false);
    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        const localApiKey = localStorage.getItem('apikey')
        const stacks = localStorage.getItem('stacks')
        if (localApiKey && stacks) {
            isValidGlobalLoginJwt(localApiKey).then(isValid => {
                if (isValid) {
                    onSuccessfulLogin()
                } else {
                    setIsFetching(false)
                    setUsername("");
                    setPassword("");
                }
            })
        } else {
            setIsFetching(false)
            setUsername("");
            setPassword("");
        }
    },[onSuccessfulLogin])

    function loginUser() {
        setIsSaving(true)
        login(username, password).then(data => {
            console.log(JSON.stringify(data))
            setLoginError(false)
            localStorage.setItem('apikey', data.apikey)
            localStorage.setItem('stacks', JSON.stringify(data.stacks))
            onSuccessfulLogin()
        }).catch((error) => {
            setIsSaving(false)
            setLoginError(true)
            setPassword('')
        })
    }

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    return (
        <React.Fragment>
            {isFetching ? <Loading/> :
                <React.Fragment>
                    <Container>
                        <div className={classes.root}>
                            <Grid container direction={"row"} spacing={3} alignItems="center" justifyContent="center">
                                <Hidden xsDown>
                                    <Grid item sm={6} >
                                        <div className={classes.heroImage}/>
                                    </Grid>
                                </Hidden>
                                <Grid item sm={6} xs={12} style={{padding: 30}}>
                                    <div className={classes.logo}/>
                                    <div style={{position: 'relative', top: '20%'}}>
                                        <FormControl  style={{width: '300px'}}>
                                            <InputLabel htmlFor="username">Username (your email address)</InputLabel>
                                            <Input type="email" id="username" autoFocus={true} autoComplete='username' aria-describedby="my-helper-text" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        <FormControl style={{width: '300px'}}>
                                            <InputLabel htmlFor="password">Password</InputLabel>
                                            <Input type="password" id="password" value={password} autoComplete='current-password' onChange={(e) => setPassword(e.target.value)}/>
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        {loginError ? <FormHelperText error id="login-error">Unable to login. Either the username or password provided is incorrect.</FormHelperText>: ''}
                                        <br/>
                                        <FormControl>
                                            {isSaving ? <LoadingSmall width={40}/> : <Button disabled={!validateForm()} variant='contained' color='primary' onClick={loginUser}>Login</Button>}
                                        </FormControl>
                                    </div>
                                </Grid>

                            </Grid>
                        </div>
                    </Container>
                </React.Fragment>
            }

        </React.Fragment>
    )
}
