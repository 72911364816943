import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./Login";
import StackSwitcher from "./StackSwitcher";
import InstitutionSwitcher from "./InstitutionSwitcher";
import React, {useEffect, useState} from "react";
import Details from "./Details";
import Registrations from "./Registrations";
import {fetchRegistrations} from "../../services/ltiServiceApi";
import Error from "../util/Error";
import Loading from "../util/Loading";

export default function RegistrationContainer({isDynamic}) {

    const navigate = useNavigate()
    const location = useLocation()

    const [isLoading, setIsLoading] = useState(false)
    const [registrations, setRegistrations] = useState(null)
    const [error, setError] = useState('')

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        if (params.get('openid_configuration')) {
            localStorage.setItem('openid_configuration', params.get('openid_configuration'))
        }

        if (params.get('registration_token')) {
            localStorage.setItem('registration_token', params.get('registration_token'))
        }

    },[location.search])

    async function loadRegistrations() {
        setIsLoading(true)
        fetchRegistrations()
            .then(registrationsData => {
                setIsLoading(false)
                console.log(registrationsData.data)
                setRegistrations(registrationsData.data)
            }).catch(e => {
            setIsLoading(false)
            setError(e.message)
        })
    }

    function onSuccessfulLogin() {
        navigate('stack-switcher')
    }

    if (error) {
        return <Error message={error}/>
    }

    if (isLoading) {
        return <Loading/>
    }

    console.log('RegistrationContainer isDynamic', isDynamic)

    return (
        <Routes>
            <Route path="/*" element={<Login onSuccessfulLogin={onSuccessfulLogin}/>} />
            <Route path="/stack-switcher" element={<StackSwitcher isDynamic={isDynamic}/>} />
            <Route path="/institution-switcher" element={<InstitutionSwitcher isDynamic={isDynamic}/>} />
            <Route path="/registrations" element={<Registrations registrations={registrations} loadRegistrations={loadRegistrations}/>} />
            <Route path="/details" element={<Details registrations={registrations}/>} loadRegistrations={loadRegistrations}/>
            <Route path="/details/:registrationId" element={<Details registrations={registrations} loadRegistrations={loadRegistrations}/> } />
        </Routes>
    )
}
