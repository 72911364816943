import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Container, Grid} from "@material-ui/core";
import logo from "../../static/logo.svg"
import {useNavigate} from "react-router-dom";
import Loading from "../util/Loading";
import {fetchAdminMagicLink, fetchTimelines} from "../../services/ltiServiceApi";
import Error from "../util/Error";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles({
    root: {
        maxWidth: 445,
    },
    media: {
        height: 240,
    },
});

export default function TimelineSwitcher() {
    const classes = useStyles();
    const navigate = useNavigate()

    const [timelines, setTimelines] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')
    const [adminMagicLink, setAdminMagicLink] = useState('')

    useEffect(() => {
        Promise.all([fetchTimelines(), fetchAdminMagicLink()]).then(([timelinesData, magicLinkData]) => {
            setTimelines(timelinesData.data)
            setAdminMagicLink(magicLinkData.data)
            setIsLoading(false)
        }).catch(e => {
            setIsLoading(false)
            setError(e.message)
        })
    },[])

    function handleClick(timelineUuid) {
        console.log(timelineUuid)
        navigate('/resources/'+ timelineUuid)
    }

    if (isLoading) {
        return <Loading/>
    }

    if (error) {
        return <Error message={error}/>
    }

    function renderStacks() {
        return timelines.map(timeline => {
            return (
                <Grid key={timeline.Timeline.uuid} item lg={4} xs={6}>
                    <Card className={classes.root} onClick={() => handleClick(timeline.Timeline.uuid)}>
                        <CardActionArea>
                            <div
                                className={classes.media}
                                style={{background:"url(" + timeline.Experience.lead_url + ")", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: 'center'}}
                                // image={stack.image}
                                // title={timeline.Timeline.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {timeline.Timeline.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {timeline.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            )
        })
    }

    return (
        <Container>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{marginTop: 48}}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img alt={"Timeline banner"} src={logo} width={360}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'body1'} style={{color: 'grey', paddingBottom: 8, paddingTop: 8}}>Select the timeline you want to use.</Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                </Grid>
                {renderStacks()}
                <Grid key="create-new" item lg={4} xs={6}>
                    <Card className={classes.root}>
                        <CardActionArea href={adminMagicLink} target={"_blank"}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Create new <AddCircleIcon style={{verticalAlign: "text-top"}}/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Create a new experience from scratch
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
