import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Button, Container, Grid} from "@material-ui/core";
import logo from "../../static/logo.svg"
import {useNavigate} from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
    root: {
        // maxWidth: 445,
    },
    media: {
        height: 240,
    },
});

export default function Registrations({registrations, loadRegistrations}) {
    const classes = useStyles();
    const navigate = useNavigate()

    useEffect(() => {
        if (registrations && Array.isArray(registrations)) {
            return
        } else {
            loadRegistrations()
        }
    },[registrations, loadRegistrations])



    function handleClick(registrationId) {
        navigate('/details/' + registrationId)
    }

    function handleAddNew() {
        navigate('/details')
    }

    function renderStacks() {
        if (registrations && Array.isArray(registrations)) {
            return registrations.map(registration => {
                return (
                    <Grid key={registration.id} item lg={6}>
                        <Card className={classes.root} onClick={() => handleClick(registration.id)}>
                            <CardActionArea>
                                <CardContent style={{textAlign: "start"}}>
                                    <pre><strong>Name:</strong> {registration.name}</pre>
                                    <pre><strong>Url:</strong> {registration.url}</pre>
                                    <pre><strong>Client ID:</strong> {registration.clientId}</pre>
                                    <pre><strong>Authentication Endpoint:</strong> {registration.authenticationEndpoint}</pre>
                                    <pre><strong>Authorization Server:</strong> {registration.authorizationServer}</pre>
                                    <pre><strong>Access Token Endpoint:</strong> {registration.accesstokenEndpoint}</pre>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            })
        }
    }

    return (
        <Container>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{marginTop: 48}}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img alt={"Practera Logo"} src={logo} width={360}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'body1'} style={{color: 'grey', paddingBottom: 8, paddingTop: 8}}>Below are all of your registrations</Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                </Grid>
                {renderStacks()}
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button color={"primary"} startIcon={<AddIcon/>} variant={"contained"} size={"large"} onClick={() => handleAddNew()}>Add new registration</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
