import React, {useEffect, useState} from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import MUIDataTable from 'mui-datatables'
import Fab from '@material-ui/core/Fab'
// import ky from 'ky'
import NavigationIcon from '@material-ui/icons/Navigation'
import {CircularProgress} from "@material-ui/core";
import $ from 'jquery'
import {useParams} from "react-router-dom";
import Loading from "../util/Loading";
import {fetchResources, postResource} from "../../services/ltiServiceApi";
import Error from "../util/Error";


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },
    paper: {
        overflow: 'hidden',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    fab: {
        marginTop: theme.spacing(4)
    },
    btnDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
    logodiv: {
        marginBottom: theme.spacing(8),
        backgroundColor: 'transparent '
    },
    logo: {
        cursor: 'pointer'
    },
    margin: {
        marginTop: theme.spacing(4),
        backgroundColor: '#013b6c'
    },
    table: {
        marginTop: '10%'
    }
}))

export default function Resources () {
    const classes = useStyles()

    const { timelineUuid } = useParams()
    // const { enqueueSnackbar } = useSnackbar()
    const [resource, setResource] = useState(false)
    const [dataset, setDataset] = useState([])
    const [selected, setSelected] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState('')

    // const errorPrompt = async (message) => {
    //     enqueueSnackbar(message, { variant: 'error' })
    // }

    // Retrieves resource dataset
    useEffect(() => {
        fetchResources(timelineUuid).then(resourcesResponse => {
            console.log(resourcesResponse)
            console.log(resourcesResponse.data)
            setDataset(resourcesResponse.data)
            setIsLoading(false)
        }).catch(e => {
            setIsLoading(false)
            setError(e.message)
        })


    }, [timelineUuid])

    // Submits resource to deep linking endpoint
    const submit = async () => {
        setIsSaving(true)
        try {
            if (resource === false) {
                // errorPrompt('Please select a resource.')
                return
            }
            console.log(dataset)
            console.log(resource)
            console.log(dataset[resource])
            postResource(dataset[resource], timelineUuid).then(formResponse => {
                $('body').append(formResponse.data)
            }).catch(e => {
                setIsSaving(false)
                setError(e.message)
            })

        } catch (err) {
            console.error(err)
            setIsSaving(false)
        }
    }

    // Configuring data table
    const columns = [
        {
            name: 'name',
            label: 'Name'
        },
        {
            name: 'value',
            label: 'Value'
        }
    ]

    const options = {
        filterType: 'checkbox',
        selectableRows: 'single',
        disableToolbarSelect: true,
        download: false,
        print: false,
        searchOpen: false,
        search: true,
        viewColumns: false,
        filter: false,
        selectableRowsOnClick: false,
        onRowsSelect: (selResource, allRows) => {
            console.log(selResource)
            setResource(selResource[0].dataIndex);
            setSelected(allRows.map(row => row.dataIndex))
        },
        rowsSelected: selected,
        rowsPerPage: 5,
        responsive: 'scrollFullHeight'
    }

    function renderLoading() {
        return <div style={{textAlign: 'center', width: '100%', marginTop: 32}}><CircularProgress/></div>
    }

    if (error) {
        return <Error message={error}/>
    }

    if (isLoading) {
        return <Loading/>
    }

    return (
        <Container component='main' maxWidth='lg'>
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container>
                    <Grid item xs={12} className={classes.table}>
                        <React.Fragment>
                            <MUIDataTable
                                title='Example custom resources:'
                                data={dataset}
                                columns={columns}
                                options={options}
                            />
                            <Grid item xs className={classes.btnDiv}>
                                {isSaving ? renderLoading() :
                                    <Fab variant='extended' color='primary' aria-label='add' className={classes.fab}
                                         onClick={submit}>
                                        <NavigationIcon className={classes.extendedIcon}/>
                                        Submit
                                    </Fab>
                                }
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}
