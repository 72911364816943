import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import RegistrationContainer from "./components/registration/RegistrationContainer";
import DeeplinkContainer from "./components/deeplink/DeeplinkContainer";

function App() {
  return (
      <div>
          <Router>
              <Routes>
                  <Route path="/deeplink/*" element={<DeeplinkContainer/>} />
                  <Route path="/dynamic/*" element={<RegistrationContainer isDynamic={true}/>} />
                  <Route path="/*" element={<RegistrationContainer isDynamic={false}/>} />
              </Routes>
          </Router>
      </div>

);
}

export default App;
