import axios from "axios";
import jsonwebtoken from "jsonwebtoken";

const dotenv = require('dotenv');
const path = require('path');
const ENV_FILE = path.join(__dirname, '.env');
dotenv.config({ path: ENV_FILE });

const GLOBAL_LOGIN_PUBLIC_KEY=process.env.REACT_APP_GLOBAL_LOGIN_PUBLIC_KEY
const PRACTERA_LOGIN_URL=process.env.REACT_APP_PRACTERA_LOGIN_URL

export const login = async (email, password) => {

    const body = {
        username: email,
        password: password
    }

    return axios({
        method: 'post',
        url: PRACTERA_LOGIN_URL,
        data: body,
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }

        return response.data;

    }).catch(error => {
        console.error('==================Practera Login API Error Response===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('Query: ', body)
        console.error('===========================================================')
        throw error;
    });
}

export const isValidGlobalLoginJwt = async (jwt) => {
    if (!jwt) {
        return false;
    }
    return new Promise((resolve, reject) => {
        jsonwebtoken.verify(jwt, GLOBAL_LOGIN_PUBLIC_KEY, { algorithms: ['RS256'] },
            (err, payload) => {
                if (err) {
                    console.warn('JWT is not valid', err.message);
                    resolve(false);
                }
                resolve(true);
            }
        );
    });
};

export const getDecodedGlobalLoginJwt = async () => {
    const jwt = localStorage.getItem('apikey');
    console.log(jwt);
    if (!jwt) {
        return {};
    }
    return new Promise((resolve, reject) => {
        jsonwebtoken.verify(jwt, GLOBAL_LOGIN_PUBLIC_KEY, { algorithms: ['RS256'] },
            (err, payload) => {
                if (err) {
                    console.warn('JWT is not valid', err.message);
                    resolve({});
                }
                resolve(payload);
            }
        );
    });
};



