import {Route, Routes, useLocation} from "react-router-dom";
import Resources from "./Resources";
import TimelineSwitcher from "./TimelineSwitcher";
import {useEffect} from "react";


export default function DeeplinkContainer() {

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        if (params.get('ltik')) {
            localStorage.setItem('ltik', params.get('ltik'))
        }

    },[location.search])

    return (
        <Routes>
            <Route path="/resources/:timelineUuid" element={<Resources/>} />
            <Route path="/*" element={<TimelineSwitcher/>} />
        </Routes>
    )
}
