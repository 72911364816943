import axios from "axios";

const BASE_URL = process.env.REACT_APP_LTI_SERVICE_BASE_URL

export const fetchInstitutions = () => {
    const domain = localStorage.getItem('selectedStackDomain')
    const practeraJwt = localStorage.getItem('apikey')

    return axios.get(BASE_URL + '/institutions?practera_jwt=' + practeraJwt + '&domain=' + domain)
        .then(institutionsData => {
            console.log(institutionsData.data)
            return institutionsData
        }).catch(err => {
            if (!domain) {
                throw new Error('This user has not selected a stack')
            }
            if (!practeraJwt) {
                throw new Error('There is no apikey')
            }
            throw err
        })
}

export const fetchRegistrations = () => {
    const domain = localStorage.getItem('selectedStackDomain')
    const practeraJwt = localStorage.getItem('apikey')
    const institution_id = localStorage.getItem('institution_id')

    return axios.get(BASE_URL + '/registrations?practera_jwt=' + practeraJwt + '&domain=' + domain + '&institution_id=' + institution_id)
        .then(registrationsData => {
            console.log(registrationsData.data)
            return registrationsData
        }).catch(err => {
            if (!domain) {
                throw new Error('This user has not selected a stack')
            }
            if (!practeraJwt) {
                throw new Error('There is no apikey')
            }
            throw err
        })
}

export const fetchTimelines = () => {
    const ltik = localStorage.getItem('ltik')

    return axios.get(BASE_URL + '/timelines?ltik=' + ltik)
        .then(timelinesData => {
            console.log(timelinesData.data)
            return timelinesData
        }).catch(err => {
            if (!ltik) {
                throw new Error('You cannot access this page without an "ltik" parameter')
            }
            throw err
        })
}

export const fetchResources = (timelineUuid) => {
    const ltik = localStorage.getItem('ltik')

    return axios.get(BASE_URL + '/resources?ltik=' + ltik + '&timelineUuid=' + timelineUuid)
        .then(resourcesResponse => {
            return resourcesResponse
        }).catch(err => {
            if (!ltik) {
                throw new Error('You cannot access this page without an "ltik" parameter')
            }
            throw err
        })
}

export const fetchAdminMagicLink = () => {
    const ltik = localStorage.getItem('ltik')

    return axios.get(BASE_URL + '/admin-magic-link?ltik=' + ltik)
        .then(adminMagicLinkResponse => {
            return adminMagicLinkResponse
        }).catch(err => {
            if (!ltik) {
                throw new Error('You cannot access this page without an "ltik" parameter')
            }
            throw err
        })
}

export const postResource = (resource, timelineUuid) => {
    const ltik = localStorage.getItem('ltik')

    return axios.post(BASE_URL + '/deeplink?ltik=' + ltik,
        {
            resource: resource,
            timelineUuid: timelineUuid
        }).then(response => {
            return response
        }).catch(err => {
            if (!ltik) {
                throw new Error('You cannot access this page without an "ltik" parameter')
            }
            throw err
        })
}

export const putRegistration = (details) => {
    const domain = localStorage.getItem('selectedStackDomain')
    const practeraJwt = localStorage.getItem('apikey')
    const institution_id = localStorage.getItem('institution_id')

    return axios.put(BASE_URL + '/registration?practera_jwt=' + practeraJwt + '&domain=' + domain + '&institution_id=' + institution_id, details)
        .then(registrationsData => {
            console.log(registrationsData.data)
            return registrationsData
        }).catch(err => {
            if (!domain) {
                throw new Error('This user has not selected a stack')
            }
            if (!practeraJwt) {
                throw new Error('There is no apikey')
            }
            throw err
        })
}

export const getCompleteRegistrationLink = (practeraJwt, selectedStackDomain, institution_id, openid_configuration, registration_token) => {
    return BASE_URL + '/complete-registration?practera_jwt=' + practeraJwt + '&domain=' + selectedStackDomain + '&institution_id=' + institution_id + '&openid_configuration=' + openid_configuration + '&registration_token=' + registration_token
}
