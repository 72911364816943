import {Button, Container, Grid, TextField} from "@material-ui/core";
import logo from "../../static/logo.svg";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {putRegistration} from "../../services/ltiServiceApi";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../util/Loading";
import Error from "../util/Error";


export default function Details({registrations, loadRegistrations}) {

    const [details, setDetails] = useState({})
    const [error, setError] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const {registrationId} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (registrations && Array.isArray(registrations)) {
            return
        } else {
            if (loadRegistrations) {
                loadRegistrations()
            }
        }

    },[registrations, loadRegistrations])

    useEffect(() => {
        if (registrations && registrationId) {
            registrations.forEach(registration => {
                if (registration.id === registrationId) {
                    setDetails(registration)
                }
            })
        }
    }, [registrations, registrationId])

    function updateValue(event, key) {
        console.log(details)
        const tempDetails = Object.assign({}, details)
        tempDetails[key] = event.target.value
        setDetails(tempDetails)
    }

    function updateAuthConfig(event) {
        const tempDetails = Object.assign({}, details)
        tempDetails.authConfig = {
            method: 'JWK_SET',
            key: event.target.value
        }
        setDetails(tempDetails)
    }

    function saveRegistration() {
        setIsSaving(true)
        putRegistration(details).then(data => {
            console.log(data.data.id)
            navigate('/details/' + data.data.id)
            setIsSaving(false)
        }).catch(e => {
            setError(e.message)
        })
    }

    function canSubmit() {
        return details && details.name && details.url && details.clientId && details.authenticationEndpoint
            && details.accesstokenEndpoint && details.authConfig && details.authConfig.key && details.authConfig.method
    }

    if (error) {
        return <Error message={error}/>
    }

    if (isSaving) {
        return (
            <Loading/>
        )
    }

    return (
        <Container>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{marginTop: 48}}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img alt={"Practera Logo"} src={logo} width={360}/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={'body1'} style={{color: 'grey', paddingBottom: 8, paddingTop: 8}}>To manually register Practera as an external tool first you will need to enter these details into your LMS:</Typography>
                    <ul>
                        <li>Tool URL: <strong>https://nil85r7r30.execute-api.us-east-1.amazonaws.com/dev</strong></li>
                        <li>Public Keyset URL: <strong>https://nil85r7r30.execute-api.us-east-1.amazonaws.com/dev/keys</strong></li>
                        <li>Login URL: <strong>https://nil85r7r30.execute-api.us-east-1.amazonaws.com/dev/login</strong></li>
                        <li>Redirection URL: <strong>https://nil85r7r30.execute-api.us-east-1.amazonaws.com/dev</strong></li>
                    </ul>
                </Grid>

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'body1'} style={{color: 'grey', paddingBottom: 8, paddingTop: 8}}>LMS Details</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-full-width"
                        label="Url"
                        style={{ margin: 8 }}
                        placeholder="e.g. https://blackboard.com"
                        value={details.url ? details.url : ''}
                        onChange={(event) => updateValue(event, 'url')}
                        helperText="Url of the platform"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-full-width"
                        label="Name"
                        style={{ margin: 8 }}
                        placeholder="e.g. Blackboard"
                        value={details.name ? details.name : ''}
                        onChange={(event) => updateValue(event, 'name')}
                        helperText="Name of the platform"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-full-width"
                        label="Client ID"
                        style={{ margin: 8 }}
                        placeholder="e.g. 17fdcb17-2f9d-4780-9cfe-045..."
                        value={details.clientId ? details.clientId : ''}
                        onChange={(event) => updateValue(event, 'clientId')}
                        helperText="Tool client ID generated by the Platform"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-full-width"
                        label="Authentication URL"
                        style={{ margin: 8 }}
                        placeholder="e.g. https://developer.blackboard.com/api/v1/gateway/oidcauth"
                        value={details.authenticationEndpoint ? details.authenticationEndpoint : ''}
                        onChange={(event) => updateValue(event, 'authenticationEndpoint')}
                        helperText="Platform authentication endpoint"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-full-width"
                        label="Access token URL"
                        style={{ margin: 8 }}
                        placeholder="e.g. https://developer.blackboard.com/api/v1/gateway/oauth2/jwttoken"
                        value={details.accesstokenEndpoint ? details.accesstokenEndpoint : ''}
                        onChange={(event) => updateValue(event, 'accesstokenEndpoint')}
                        helperText="Platform access token request endpoint"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-full-width"
                        label="Jwks URL"
                        style={{ margin: 8 }}
                        placeholder="e.g. https://developer.blackboard.com/api/v1/management/applications/17fdcb17-2f9d-4780-9cfe-0456046be776/jwks.json"
                        value={details.authConfig && details.authConfig.key ? details.authConfig.key : ''}
                        onChange={(event) => updateAuthConfig(event)}
                        helperText="Endpoint for the jwks for the platform"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} style={{textAlign: "right", marginBottom: 32}}>
                    <Button onClick={() => navigate('/registrations')}>Cancel</Button>
                    <Button disabled={!canSubmit()} variant={"contained"} color={"primary"} style={{marginLeft: 32}} onClick={saveRegistration}>Save</Button>
                </Grid>

            </Grid>
        </Container>
    );

}
