import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Container, Grid} from "@material-ui/core";
import logo from "../../static/logo.svg"
import Loading from "../util/Loading";
import {fetchInstitutions, getCompleteRegistrationLink} from "../../services/ltiServiceApi";
import Error from "../util/Error";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 445,
    },
    media: {
        height: 240,
    },
});

export default function InstitutionSwitcher({isDynamic}) {
    const classes = useStyles();
    const navigate = useNavigate()

    const [institutions, setInstitutions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')

    useEffect(() => {

        fetchInstitutions()
            .then(institutionsData => {
                setIsLoading(false)
                console.log(institutionsData.data)
                setInstitutions(institutionsData.data)
            }).catch(e => {
                setIsLoading(false)
                setError(e.message)
            })

    },[])

    function handleClick(institution_id) {
        console.log(institution_id)
        setIsLoading(true)
        console.log('handleClick isDynamic', isDynamic)
        localStorage.setItem('institution_id', institution_id)
        if (isDynamic) {
            window.location = getCompleteRegistrationLink(localStorage.getItem('apikey'), localStorage.getItem('selectedStackDomain'), institution_id, localStorage.getItem('openid_configuration'), localStorage.getItem('registration_token'))
        } else {
            navigate('/registrations')
        }
    }

    function renderStacks() {
        if (institutions && Array.isArray(institutions) && institutions.length > 0) {
            return institutions.map(institution => {
                return (
                    <Grid key={institution.Institution.id} item lg={3}>
                        <Card className={classes.root} onClick={() => handleClick(institution.Institution.id)}>
                            <CardActionArea>
                                <CardContent style={{textAlign: "center"}}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {institution.Institution.name}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            })
        } else {
            return <Typography variant={'body1'}>There are no institutions associated with your account</Typography>
        }
    }

    if (error) {
        return <Error message={error}/>
    }

    if (isLoading) {
        return <Loading/>
    }

    return (
        <Container>
            <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} style={{marginTop: 48}}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <img alt={"Practera Logo"} src={logo} width={360}/>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant={'body1'} style={{color: 'grey', paddingBottom: 8, paddingTop: 8}}>Select the institution you want to use.</Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                </Grid>
                {renderStacks()}
            </Grid>
        </Container>
    );
}
